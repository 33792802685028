import React from "react";
import Footer from "../Layout/footer";
import "./contactmodule.css";
import logo from "../../assets/images/tarana-logo.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
<link rel="stylesheet" href="	https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"></link>

export const Contactus = () =>{
    return(
        <div>
        <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Tarana logo"
              width="30"
              height="30"
              className="d-inline-block align-text-top logoImage me-2"
            />
            <span className="navbar-brand-text">tarana</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
         <div
            className="collapse navbar-collapse nav-text"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active nav-link-home"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Listeners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  FAQs
                </a>
              </li>
            </ul>
            </div>
        </div>
      </nav>

      <div>
    <div className="container-fluid text-center mb-3 mt-3" style={{fontFamily: "'Cuprum',sanserif"}}>
            <h1>
                Contact <span className="custom-us"> us</span>
            </h1>
            <h4>
           <span className="custom-us"> Questions? </span> We've Got Answers!
            </h4>
            <h4>
            Fill out the form below, and we'll get back to you shortly.
            </h4>
        </div>
      </div>

      <div className="container-fluid custom-contact">
<form className="row g-3">
 <div className="d-flex" style={{gap: "5px"}}>
  <div className="col-12 col-sm-6 mb-3">
    <input
      type="text"
      className="form-control"
      placeholder="First name"
      aria-label="First name"
    />
  </div>
  <div className="col-12 col-sm-6 mb-3 mt-sm-0">
    <input
      type="text"
      className="form-control"
      placeholder="Last name"
      aria-label="Last name"
    />
  </div>
  </div>
  
        <div className="col-12 mb-3">
            <input
                type="text"
                className="form-control"
                id="inputAddress"
                placeholder="E-mail"
            />
        </div>
        <div className="col-12 mb-3">
            <input
                type="text"
                className="form-control formcontrol-custom"
                id="inputAddress2"
                placeholder="How can we help you"
            />
        </div>
        
        <div className="col-12 d-flex justify-content-center mb-3">
            <button type="button" className="btn btn-secondary" style={{backgroundColor: "#ff5906"}}>Send Message</button>
        </div>
    </form>
</div>

<Footer/>
  </div>


    );
};