import logo from "../../assets/images/tarana-logo.png";
import "./stylemodule.css";
import dileep from "../../assets/images/dileep image.svg.svg";
import Lakshmi from "../../assets/images/lakshmikanth.svg";
import Vinay from "../../assets/images/vinay.svg";
import Vamsi from "../../assets/images/Vamsi.svg";
import linkedin from "../../assets/images/social/linkedIn.png";
import Footer from "../Layout/footer";
import cardSvg1 from "../../assets/images/socialnetworking.svg";
import cardSvg2 from "../../assets/images/freelancing.svg";
import cardSvg3 from "../../assets/images/collab.svg";
import cardSvg4 from "../../assets/images/content_creation.svg";
import cardSvg5 from "../../assets/images/AudioAndVideoCopy.svg";
import cardSvg6 from "../../assets/images/share_music.svg";
import cardSvg7 from "../../assets/images/hire.svg";
import cardSvg8 from "../../assets/images/variety.svg";
import cardSvg9 from "../../assets/images/Discover.svg";
import cardSvg10 from "../../assets/images/Audience_artits.svg";
import article1 from "../../assets/images/art1.svg";
import article2 from "../../assets/images/art2.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ReusableAxios } from "../../utils/modules/reUsableAxios";

export const Home = () => {
  const navigate = useNavigate();
  const { section } = useParams();
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [notifyInput, setNotifyInput] = useState<any>();
  const [notifyRadio, setNotifyRadio] = useState<any>();
  const [successMsg, setSuccessMsg] = useState<string>("");

  const handleNotifiy = async () => {
    if (notifyInput && emailPattern.test(notifyInput) && notifyRadio) {
      const url = "https://taranamusic.in/TaranaUser_Api/notify";
      const data = {
        email: notifyInput,
        preference: notifyRadio,
      };
      const response = await ReusableAxios(url, "POST", data);
      if (response.status === 201) {
        setSuccessMsg(response.data.message);
      } else {
        setSuccessMsg(response.data.message);
      }
    }
  };

  useEffect(() => {
    if (section) {
      handleScroll(null, section);
    }
  });

  const handleScroll = (event: any, targetId: any) => {
    if (event) {
      event.preventDefault();
    }
    const section = document.getElementById(targetId);
    section?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="layout-color">
      <div className="nav-background">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img
                src={logo}
                alt="Tarana logo"
                width="43"
                height="43"
                className="d-inline-block align-text-top logoImage me-1"
              />
              <span className="navbar-brand-text">tarana</span>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className="navbar-toggler-icon"
                style={{
                  backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")`,
                }}
              ></span>
            </button>
            <div
              className="collapse navbar-collapse  nav-text"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active nav-link-home"
                    aria-current="page"
                    href="#"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-home"
                    href="#artists"
                    onClick={(e) => handleScroll(e, "artists")}
                  >
                    For Artists
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-home"
                    href="#listeners"
                    onClick={(e) => handleScroll(e, "listeners")}
                  >
                    For Listeners
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-home"
                    href="#articles"
                    onClick={(e) => handleScroll(e, "articles")}
                  >
                    Articles
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link nav-link-home"
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  >
                    About Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="background-image">
        <div className="banner-container position-relative">
          <div className="custom-bannertext d-flex flex-column justify-content-center align-items-center text-center text-white position-absolute top-50 start-50 translate-middle">
            <p className="custom-text mb-0">MEET TARANA,</p>
            <div className="wrapperForSpin">
              <div className="spinWords">
                <span className="custom-text spinSpan">WORLD'S</span>
                <span className="custom-text spinSpan indias">INDIA'S</span>
              </div>
              <p className="custom-text paragraph">FIRST</p>
              <div className="spinWords">
                <span className="custom-text spinSpan">NETWORKING</span>
                <span className="custom-text spinSpan">FREELANCING</span>
              </div>
            </div>
            <p className="custom-text">PLATFORM FOR MUSIC</p>
            <p className="custom-text-3">
              {" "}
              <span
                style={{
                  color: "#FF5906",
                }}
              >
                Tarana{" "}
              </span>{" "}
              is a groundbreaking music platform, merging social networking,
              streaming, and freelancing to create a unique and holistic music
              experience. Join us in creating a unique music ecosystem.
            </p>
            <button type="button" className="custom-btn">
              Launching very soon..!
            </button>
          </div>
        </div>
      </div>
      <div className="artistsContianer" id="artists">
        <div className="text-section text-center mt-5 mb-5 mt-md-6 mb-md-6">
          <h2>
            Tarana for <span className="highlight">Artists</span>
          </h2>
          <p className="text-muted">
            <span className="highlight">Tarana</span>: Your all-in-one hub for
            music artists. Collaborate, freelance, and launch your solo career
            or form bands effortlessly. Whether you're a struggling artist or an
            aspiring one, <span className="highlight">Tarana</span> is your
            go-to platform. Stream your songs seamlessly without distributors.
            Let <span className="highlight">Tarana</span> handle the rest while
            you focus on content creation. Transform your musical journey and
            become the next Rockstar.
          </p>
        </div>
        <div>
          <p className="custom-power">
            Powerful Tools for Artists in{" "}
            <span className="highlight">Tarana</span>
          </p>
        </div>
        <br />
        {/* <div className="container d-flex justify-content-center"> */}
        <div className="p-0 m-0">
          <div className="row g-4">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg1}
                  className="img-fluid"
                  alt="Card Image 1"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Dedicated Social Networking
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Tarana offers potent tools for connecting and communicating
                    with artists globally. Experience a dedicated social media
                    platform crafted to cater to the specific needs of artists.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg2}
                  className="img-fluid"
                  alt="Card Image 2"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Freelancing
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Tarana presents a powerful freelancing tool to elevate your
                    music career, enabling you to boost your earnings.
                    effortlessly discover gigs that align with your musical
                    expertise, requiring minimal effort on your part.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg3}
                  className="img-fluid"
                  alt="Card Image 3"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Collaboration
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    In Tarana, collaboration transcends borders, fostering
                    dynamic musical partnerships among like-minded artists,
                    sparking creativity, and fueling a global symphony of
                    collaborative talent.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg4}
                  className="img-fluid"
                  alt="Card Image 4"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Content Creation
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Tarana Content creation stands as a formidable tool for
                    artists to generate income. By providing a direct upload
                    option, Tarana removes intermediaries, placing everything
                    under your control.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="artistsContianer" id="listeners">
        <div className="p-0 m-0">
          <div className="text-section text-center mt-mb-5 mb-5 mt-md-6 mb-md-6">
            <h2>
              Tarana for <span className="highlight">Listners</span>
            </h2>
            <p className="text-muted">
              Greetings, music enthusiasts! Brace yourself for an innovative
              journey with <span className="highlight">Tarana</span>, unlocking
              a realm of possibilities. More than just a music platform,{" "}
              <span className="highlight">Tarana</span> invites you to immerse
              yourself in the freedom of melodies that speak to your soul. Here,
              you're not merely a listener but a vital part of a vibrant
              community, honoring the enchantment of music. Step into a new era
              where your passion for music converges with unparalleled
              freedom—welcome to the <span className="highlight">Tarana</span>!
            </p>
          </div>
          <div>
            <p className="custom-power">
              Now, <span className="highlight">enjoy</span>
            </p>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg5}
                  className="img-fluid"
                  alt="Card Image 5"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Audio & Video Content at one place
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Discover the joy of audio and video content all in one place
                    at Tarana. Dive into a rich world of melodies and
                    captivating visuals that elevate your music experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg6}
                  className="img-fluid"
                  alt="Card Image 6"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Sharing music
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Now that social interaction with friends is different, share
                    status updates of your favorite music with your loved ones.
                    Make music speak more.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg7}
                  className="img-fluid"
                  alt="Card Image 7"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Hire Talented Artists
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Hire talented artists for concerts, weddings, and more with
                    Tarana! Simply post your job, connect with skilled
                    performers, and elevate your events with the magic of music.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg8}
                  className="img-fluid"
                  alt="Card Image 8"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Variety of Music Content
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Tarana: Your Hub for Diverse Music and Entertainment Content
                    – From Original Tracks and Remixes to Engaging Videos, We've
                    Got It All!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg9}
                  className="img-fluid"
                  alt="Card Image 9"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Discover New Artists
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Discover emerging talents on Tarana! 🌟 Unearth fresh
                    sounds, connect with rising artists, and be part of a
                    vibrant musical community.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div
                className="card h-100 p-4"
                style={{
                  backgroundColor: "#FEF4EA",
                  borderRadius: "24px",
                }}
              >
                <img
                  src={cardSvg10}
                  className="img-fluid"
                  alt="Card Image 10"
                  style={{ height: "200px" }}
                />
                <div className="card-body">
                  <h5
                    className="card-title mt-4 mb-3"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      color: "#ff5906",
                      fontSize: "23px",
                    }}
                  >
                    Audience-Artist Interaction
                  </h5>
                  <p
                    className="card-text"
                    style={{
                      fontFamily: "Cuprum, sans-serif",
                      fontSize: "19px",
                      color: "#1D1918",
                      lineHeight: "21.95px",
                    }}
                  >
                    Tarana provides a range of tools for audience-artist
                    interaction, enhancing the overall experience and making it
                    a dynamic platform for both creators and listeners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="Layout">
        <div className="experience-section">
          <h2
            className="text-start p-2"
            style={{
              fontFamily: "'Cuprum',sans-serif",
              color: "rgba(245, 245, 245, 1)",
            }}
          >
            Excited about{" "}
            <span style={{ color: "rgba(255, 89, 6, 1)" }}>Tarana?</span>
            Subscribe now for{" "}
            <span style={{ color: "rgba(255, 89, 6, 1)" }}>Early Access!</span>
          </h2>
          {successMsg !== "" ? (
            <div className="row g-3 py-3">
              <div>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Cuprum, sanserif",
                    fontSize: "19px",
                  }}
                >
                  {successMsg}
                </p>
              </div>
              <div>
                <button className="notified-btn">You'll be notified</button>
              </div>
            </div>
          ) : (
            <div className="row g-3 py-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label
                  htmlFor="mailInput"
                  style={{
                    color: "#fff",
                    fontFamily: "'Cuprum',sans-serif",
                    fontSize: "19px",
                  }}
                >
                  Enter your Email I'd
                </label>
                <input
                  id="mailInput"
                  type="text"
                  className="form-control custom-input"
                  placeholder=""
                  aria-label="Email"
                  style={{
                    height: "48px",
                    border: "none",
                    borderRadius: "25px",
                  }}
                  onChange={(event: any) => {
                    setNotifyInput(event.target.value);
                  }}
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <label
                  htmlFor="exampleCheck1"
                  style={{
                    color: "#fff",
                    fontFamily: "'Cuprum',sans-serif",
                    fontSize: "19px",
                  }}
                >
                  Are you a music
                </label>
                <div style={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
                  <div className="custom-type-div">
                    <input
                      type="radio"
                      id="exampleCheck1"
                      name="musicType"
                      onChange={(event: any) => {
                        setNotifyRadio("creator");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck1"
                      style={{ marginLeft: "8px" }}
                    >
                      Creator?
                    </label>
                  </div>
                  <div className="custom-type-div">
                    <input
                      type="radio"
                      id="exampleCheck2"
                      name="musicType"
                      onChange={(event: any) => {
                        setNotifyRadio("listener");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleCheck2"
                      style={{ marginLeft: "8px" }}
                    >
                      Listener?
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-xs-12 custom-notify-btn-div">
                <button className="notify-btn" onClick={handleNotifiy}>
                  Notify Me
                </button>
              </div>
            </div>
          )}
        </div>
      </div> */}

      <br />

      <div
        className="Layout text-section text-center"
        id="articles"
        style={{ padding: "48px 0 0 0" }}
      >
        <h1 className="Custom-articles">Articles</h1>
      </div>

      <div className="Layout">
        <div className="row articlesRow">
          <div className="col">
            <div className="card customm-card">
              <div className="image-container">
                <img
                  src={article1}
                  className="article-img img-fluid"
                  alt="..."
                  style={{ borderRadius: "16px" }}
                />
              </div>
              <div className="custom-card-bodyy">
                <h2 className="customm-heading">
                  Collaborate, Create, Succeed: Tarana for Musicians
                </h2>
                <p className="articlescard-text">
                  In today’s digital age, the music industry is evolving
                  rapidly, and musicians need more than just talent to
                  succeed—they need the right tools and a supportive community.
                  Enter Tarana, a groundbreaking platform designed to
                  revolutionize how musicians create, collaborate, and
                  ultimately succeed in their careers.
                </p>
                <button
                  type="button"
                  className="btn btn-warning read-more-btn"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    navigate("/articles/0");
                  }}
                >
                  Read more
                </button>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card customm-card">
              <div className="image-container">
                <img
                  src={article2}
                  className="article-img img-fluid"
                  alt="..."
                  style={{ borderRadius: "16px" }}
                />
              </div>
              <div className="custom-card-bodyy">
                <h2 className="customm-heading">
                  Tarana: Where Music Lovers and Creators Unite
                </h2>
                <p className="articlescard-text">
                  Music has always been a universal language that connects us
                  all, and in today’s fast-paced digital world, finding a
                  platform that truly enhances your listening experience is
                  essential. That is where Tarana comes in—a dynamic,
                  one-of-a-kind music platform designed to give listeners like
                  you an immersive and interactive experience with music from
                  around the world.
                </p>
                <button
                  type="button"
                  className="btn btn-warning read-more-btn"
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    navigate("/articles/1");
                  }}
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="Layout text-section text-center mt-mb-5 mb-5 mt-md-6 mb-md-6"
        style={{ padding: "48px 0 0 0" }}
      >
        <div className="Layout text-section text-center">
          <h1 className="Custom-articles">Meet Our Team</h1>
          <p className="text-muted">
            Meet our passionate team at Tarana. Comprising skilled developers,
            creative designers, and diverse talents, we're united by a shared
            vision to revolutionize music experiences. Together, we bring
            innovation, expertise, and a deep love for music to the heart of
            Tarana.
          </p>
        </div>
      </div>
      <div className="team-section Layout">
        <div className="container-fluid p-0">
          <div className="row  g-1 justify-content-center teamcustom-grid">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                    // borderTopRightRadius: "24px",
                  }}
                >
                  <img
                    src={dileep}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "24px",
                      // borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">
                    Sangi Dileep Chakravarthi
                  </p>
                  <p className="team-role-text">
                    Founder & Chief Executive Officer
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                    // borderTopRightRadius: "24px",
                  }}
                >
                  <img
                    src={Lakshmi}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "24px",
                      // borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">Athmakuri Laxmi Kanth</p>
                  <p className="team-role-text">
                    Co-Founder & Chief Marketing Officer
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                    // borderTopRightRadius: "24px",
                  }}
                >
                  <img
                    src={Vinay}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      // borderTopLeftRadius: "24px",
                      borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">
                    Chitturi Vinay Manikanta
                  </p>
                  <p className="team-role-text">Chief of Backend Technology</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                  }}
                >
                  <img
                    src={Vamsi}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">Vamshi Krishna</p>
                  <p className="team-role-text">Chief of Frontend Technology</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
