import article1 from "../../src/assets/images/article1.svg";
import article2 from "../../src/assets/images/article2.svg";

export const articlesData = [
  {
    id: 1,
    title: "Collaborate, Create, Succeed: Tarana for Musicians",
    image: article1,
    paragraphs: [
      {
        heading: "",
        paragraph: [
          "In today’s digital age, the music industry is evolving rapidly, and musicians need more than just talent to succeed—they need the right tools and a supportive community. Enter Tarana, a groundbreaking platform designed to revolutionize how musicians create, collaborate, and ultimately succeed in their careers.",
        ],
      },
      {
        heading: "Collaborate Like Never Before",
        paragraph: [
          "At the heart of Tarana is the belief that music thrives on collaboration. Whether you are a solo artist looking to expand your sound, a songwriter in need of a vocalist, or a producer searching for the perfect instrumentalist, Tarana’s collaboration features are here to make connections easier than ever. Through our platform, you can network with other artists, share ideas, and work together on projects regardless of geographical boundaries.",
          "Imagine being able to work with musicians from various parts of the world, blending diverse styles and genres to create something truly unique. Tarana makes this possible with intuitive collaboration tools that allow you to brainstorm, share tracks, and communicate effortlessly with your peers. No matter where you are, Tarana brings the studio to you.",
        ],
      },
      {
        heading: "Create with Freedom and Flexibility",
        paragraph: [
          "Creativity knows no bounds, and Tarana is built to support that philosophy. We provide a versatile environment where artists can express themselves without limitations. Whether you are working on an acoustic ballad or an electronic dance track, Tarana gives you the freedom to create in your own style and at your own pace.",
          "Our platform is equipped with advanced tools that cater to both beginners and professionals. From multi-language song support to streaming services that allow artists to reach a global audience, Tarana is designed to empower you to create your best work. Tarana also allows musicians to easily upload and share video content, whether it is a music video, a live performance, or a creative visual project. And with the integration of blockchain technology for royalty management, you can rest assured that your creations are protected, and you are fairly compensated for your talent.",
        ],
      },
      {
        heading: "Succeed Together",
        paragraph: [
          "Success in the music industry is no longer just about getting your big break—it is about building a sustainable career with the right support system. Tarana is more than just a platform; it is a community. We believe that when musicians succeed together, the whole industry thrives.",
          "With Tarana, you are not just uploading tracks—you are building a brand, engaging with fans, and exploring new opportunities. Our platform offers a space where you can highlight your music, collaborate with other artists, and even find freelance gigs that match your skills. Whether you are looking to grow your fanbase, land freelance work, or collaborate with fellow musicians, Tarana is here to help you succeed.",
          "We understand the challenges that come with being an independent musician, which is why Tarana is designed to be more than just a tool—it is a partner in your journey.",
        ],
      },
      {
        heading: "Conclusion",
        paragraph: [
          "Tarana is redefining what it means to be a successful musician in the digital age. By bringing together collaboration, creativity, and community, we are building a platform that not only supports artists but also helps them thrive.",
          "Join Tarana and be part of a future where music connects the world. Connect, collaborate, and create thrive with Tarana—where your musical journey takes off.",
        ],
      },
    ],
    articleFooterText: "CATCH YOU ON THE BEAT!",
  },
  {
    id: 2,
    title: "Tarana - Where Music Lovers and Creators Unite",
    image: article2,
    paragraphs: [
      {
        heading: "",
        paragraph: [
          "Music has always been a universal language that connects us all, and in today’s fast-paced digital world, finding a platform that truly enhances your listening experience is essential. That is where Tarana comes in—a dynamic, one-of-a-kind music platform designed to give listeners like you an immersive and interactive experience with music from around the world.",
        ],
      },
      {
        heading: "Discover Fresh and Diverse Music",
        paragraph: [
          "One of the most exciting aspects of Tarana is the incredible diversity of music available at your fingertips. Whether you are into the latest chart-toppers or seeking out hidden gems from independent artists, Tarana offers an expansive library that caters to every taste. Explore genres you love and discover new sounds you have never heard before—from Afrobeat to Indie Pop, from Blues to EDM, Tarana has it all.",
          "Our platform is designed to introduce you to a world of music beyond the mainstream. With our curated playlists and personalized recommendations, you can dive deep into your favorite genres or explore new ones. Tarana brings together artists from across the globe, giving you access to music that transcends borders and cultures.",
        ],
      },
      {
        heading: "Watch Music Videos and Listen to Audio in One Place",
        paragraph: [
          "With Tarana, your music experience is not limited to just listening. Our platform also hosts a vast collection of music videos, allowing you to enjoy both audio and visual content all in one place. Whether you are in the mood for a music video marathon or simply want to watch live performances by your favorite artists, Tarana has you covered.",
        ],
      },
      {
        heading: "Stay Connected with Your Favorite Artists",
        paragraph: [
          "Tarana is not just about consuming music—it is about connecting with the artists who create it. On our platform, you can follow your favorite musicians, stay updated on their latest releases, and engage with them directly. Whether it is through exclusive content, live Q&A sessions, or behind-the-scenes glimpses into their creative process, Tarana bridges the gap between artists and fans.",
        ],
      },
      {
        heading: "Support Independent Artists",
        paragraph: [
          "In addition to discovering and enjoying music, Tarana gives you the unique opportunity to support independent artists directly. Many of the musicians on our platform are upcoming talents who rely on platforms like Tarana to reach a broader audience. By listening to their music, sharing their tracks and videos, and engaging with their content, you play a vital role in their growth and success.",
          "Tarana’s innovative approach to monetization ensures that artists are fairly compensated for their work, allowing them to continue creating the music you love. By choosing Tarana, you are not just a listener—you are a patron of the arts, helping to sustain and elevate the global music community.",
        ],
      },
      {
        heading: "A Personalized Listening Experience",
        paragraph: [
          "We know that every music lover has their own unique tastes and preferences, which is why Tarana offers a highly personalized listening experience. Our advanced algorithms analyse your listening habits to provide recommendations tailored specifically to you. Whether you are in the mood for something upbeat or looking for a mellow vibe, Tarana’s recommendations will always hit the right note.",
        ],
      },
      {
        heading: "Hire Artists for Your Events",
        paragraph: [
          "Tarana is not just a music streaming platform; it is also a place where you can connect with talented artists for your events. Whether you are planning a wedding, corporate event, or private party, you can easily hire musicians through Tarana to make your event unforgettable. Our platform connects you with a diverse pool of artists, ensuring you find the perfect match for your musical needs.",
        ],
      },
      {
        heading: "Conclusion",
        paragraph: [
          "Tarana is revolutionizing the way we listen to and engage with music. For listeners, it is not just a platform; it is a gateway to a world of discovery, connection, and support for the artists you love. With Tarana, you are not just pressing play—you are becoming part of a global music community that celebrates creativity, diversity, and the joy of music.",
          "Join us on Tarana and experience music like never before. Discover new sounds, connect with your favorite artists, share music with friends, and immerse yourself in a world where music truly comes alive.",
        ],
      },
    ],
    articleFooterText: "CATCH YOU ON THE BEAT!",
  },
];
