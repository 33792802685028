import logo from "../../assets/images/tarana-logo.png";
import "./articlesmodules.css"; // Import the CSS file
import Footer from "../Layout/footer";
import { articlesData } from "../../utils/articlesData";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const Articles = () => {
  const { articleId } = useParams();
  const [count, setCount] = useState(0);

  useEffect(() => {
      handleScroll();
  }, []);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="main-article">
      <nav className="navbar navbar-expand-lg custom-navbar" id="main-nav">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Tarana logo"
              width="43"
              height="43"
              className="d-inline-block align-text-top logoImage me-2"
            />
            <span className="navbar-brand-text">tarana</span>
          </a>
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse nav-text"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active nav-link-home"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/artists">
                  For Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/listeners">
                  For Listeners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/articles">
                  Articles
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/about-us">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="bg-light">
        <div>
          <h1 className="custom-titletext">
            {articlesData[articleId ? parseInt(articleId) : 0].title}
          </h1>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ height: "538px" }}
        >
          <img
            src={articlesData[articleId ? parseInt(articleId) : 0].image}
            className="custom-articlesbanner img-fluid"
            alt="banner"
          />
        </div>
        <div>
          <div className="custom-alltexts">
            {articlesData[articleId ? parseInt(articleId) : 0].paragraphs.map(
              (item: any, index: number) => {
                return (
                  <div key={index}>
                    <h3 className="custom-headingText">{item.heading}</h3>
                    {item?.paragraph?.map(
                      (paragraphItem: any, pIndex: number) => {
                        return (
                          <p className="custom-paratext" key={pIndex}>
                            {paragraphItem}
                          </p>
                        );
                      }
                    )}
                  </div>
                );
              }
            )}
            <div className="custom-catch">
              {
                articlesData[articleId ? parseInt(articleId) : 0]
                  .articleFooterText
              }
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
