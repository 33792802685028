import "./Aboutusmodule.css";
import Footer from "../Layout/footer";
import logo from "../../assets/images/tarana-logo.png"; // Ensure the path is correct
import teamImg1 from "../../assets/images/teamImages/dileep.png";
import linkedin from "../../assets/images/social/linkedIn.png";
import dileep from "../../assets/images/dileep image.svg.svg";
import Lakshmi from "../../assets/images/lakshmikanth.svg";
import Vinay from "../../assets/images/vinay.svg";
import Vamsi from "../../assets/images/Vamsi.svg";

export const Aboutus = () => {
  return (
    <div className="aboutus">
      <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Tarana logo"
              width="43"
              height="43"
              className="d-inline-block align-text-top logoImage me-2"
            />
            <span className="navbar-brand-text">tarana</span>
          </a>
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              border: "none",
              backgroundColor: "transparent",
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse nav-text"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active nav-link-home"
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/artists">
                  For Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/listeners">
                  For Listeners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="/home/articles">
                  Articles
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="Layout w-100" style={{paddingBottom: "48px"}}>
        <h1
          className="text-center mt-3 mb-3"
          style={{ fontFamily: "'Cuprum',sanserif" }}
        >
          About <span style={{ color: "#ff9506" }}> us</span>
        </h1>
        <p
          className=""
          style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}
        >
          Welcome to Tarana, where music meets innovation! We are a cutting-edge
          platform that brings together the world of music, creativity, and
          technology. Tarana is not just another music app; it is a thriving
          ecosystem designed to empower musicians, artists, and creative
          professionals globally.
        </p>
        <p
          className=""
          style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}
        >
          At Tarana, we believe in the power of collaboration and community. Our
          platform offers a unique blend of social networking, music streaming,
          and freelancing tools, all in one place. Whether you are an emerging
          artist or a seasoned musician, Tarana provides you with the tools to
          create, share, and monetize your music like never before.
        </p>
        <p
          className=""
          style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}
        >
          We are proud to offer multi-language support for songs, ensuring that
          your music can reach diverse audiences across the globe. With features
          like community engagement, collaboration tools, and the chance to work
          with big names in the industry, Tarana is the place where your musical
          journey can truly take off.
        </p>
        <p
          className=""
          style={{
            fontFamily: "'Cuprum',sanserif",
            lineHeight: "26.57px",
            fontSize: "19px",
          }}
        >
          Join us at Tarana and become a part of the future of music. Let us
          create, collaborate, and celebrate music together!
        </p>
      </div>

      <div className="Layout">
        <div className="">
          <h1 style={{ fontFamily: "'Cuprum',sanserif", color: "#000" }}>
            Meet Our <span style={{ color: "#ff9506" }}> Team</span>
          </h1>
          <p
            className=""
            style={{
              fontFamily: "'Cuprum',sanserif",
              lineHeight: "26.57px",
              fontSize: "19px",
            }}
          >
            Meet our passionate team at Tarana. Comprising skilled developers,
            creative designers, and diverse talents, we're united by a shared
            vision to revolutionize music experiences. Together, we bring
            innovation, expertise, and a deep love for music to the heart of
            Tarana.
          </p>
        </div>
      </div>
      <div className="Layout">
        <div className="container-fluid p-0">
          <div className="row  g-1 justify-content-center teamcustom-grid">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                    // borderTopRightRadius: "24px",
                  }}
                >
                  <img
                    src={dileep}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "24px",
                      // borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">
                    Sangi Dileep Chakravarthi
                  </p>
                  <p className="team-role-text">
                    Founder & Chief Executive Officer
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                    // borderTopRightRadius: "24px",
                  }}
                >
                  <img
                    src={Lakshmi}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "24px",
                      // borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">Athmakuri Laxmi Kanth</p>
                  <p className="team-role-text">Co-Founder & Chief Marketing Officer</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                    // borderTopRightRadius: "24px",
                  }}
                >
                  <img
                    src={Vinay}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      // borderTopLeftRadius: "24px",
                      borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">
                    Chitturi Vinay Manikanta
                  </p>
                  <p className="team-role-text">Chief of Backend Technology</p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="card custom-card-team">
                <div
                  className="teamcard-img-top "
                  style={{
                    borderRadius: "24px",
                  }}
                >
                  <img
                    src={Vamsi}
                    className="teamcard-img-custom img-fluid w-auto"
                    alt="..."
                    style={{
                      height: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                      borderRadius: "24px",
                    }}
                  />
                  {/* <img src={linkedin} className="linkedin-img-cudtom img-fluid" alt="..."  /> */}
                </div>
                <div className="card-body teamcard-body-custom">
                  <p className="team-card-text mb-1">Vamshi Krishna</p>
                  <p className="team-role-text">Chief of Frontend Technology</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
