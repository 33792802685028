import React from "react";
import sponser from "../../assets/images/teamImages/sponser.png";
import startup from "../../assets/images/teamImages/startup.png";
import taranalogo2 from "../../assets/images/tarana-logo2.png";
import youtube from "../../assets/images/youtube.png";
import twitter from "../../assets/images/twitter.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import linkedin from "../../assets/images/linkedin.png";
import "./FooterModulemodule.css";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-4 pb-4 footer-main">
      <div className="" style={{ width: "100%" }}>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-lg-0 footer-one">
            {/* Social Media Icons */}
            <div className="d-flex align-items-center custom-justify">
              <img
                src={taranalogo2}
                alt="Tarana logo"
                width="50"
                height="50"
                className="d-inline-block align-text-top logoImage me-1"
              />
              <p className="custom-tarana-title">tarana</p>
            </div>
            <div className="links-font">
              <p>Building The Most Efficient Music Ecosystem</p>
            </div>
            <div className="supported-by custom-text custom-tarana">
              <h4 className="me-2 mb-0 tarana-color custom-tarana">
                Supported by,
              </h4>
              <div className="ms-2">
                <img
                  src={sponser}
                  alt="Sponsor logo"
                  width="80"
                  height="42"
                  className="d-inline-block align-text-top logoImage me-2"
                />
                <img
                  src={startup}
                  alt="Startup logo"
                  width="150"
                  height="42"
                  className="d-inline-block align-text-top logoImage me-2"
                />
              </div>
            </div>
            <br />
            <div
              className="col-lg-4 col-md-6 mb-lg-0 d-flex social-icons"
              style={{ fontSize: "24px" }}
            >
              <a href="https://www.youtube.com/@taranamusic04?sub_confirmation=1" target="_blank">
                <i className="bi bi-youtube" style={{ padding: "0 5px", color:"#fff", cursor: "pointer" }}></i>
              </a>
              <a href="https://twitter.com/heytarana" target="_blank">
                <i className="bi bi-twitter-x" style={{ padding: "0 5px", color:"#fff", cursor: "pointer" }}></i>
              </a>
              <a href="https://www.facebook.com/profile.php?id=100089084808174" target="_blank">
                <i className="bi bi-facebook" style={{ padding: "0 5px", color:"#fff", cursor: "pointer" }}></i>
              </a>
              <a href="https://www.instagram.com/taranamusic.in" target="_blank">
                <i className="bi bi-instagram" style={{ padding: "0 5px", color:"#fff", cursor: "pointer" }}></i>
              </a>
              <a href="https://www.linkedin.com/company/taranamusic/" target="_blank">
                <i className="bi bi-linkedin" style={{ padding: "0 5px", color:"#fff", cursor: "pointer" }}></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-lg-0 text-center footer-two custom-tarana">
            {/* Useful Links */}
            <h5 className="custom-tarana tarana-color">Useful links</h5>
            <div>
              <p className="links-font" style={{ margin: "0" }}>
                Terms & Conditions
              </p>
              <p className="links-font" style={{ margin: "0" }}>
                Privacy Policies
              </p>
              <p className="links-font" style={{ margin: "0" }}>
                Community Guidelines
              </p>
              <p className="links-font" style={{ margin: "0" }}>
                Content Policies
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-lg-0 footer-three text-right">
            {/* Contact Information */}
            <h5 className="custom-tarana tarana-color">Company</h5>
            <div className="footer-three custom-tarana contact-info-wrapper">
              <div className="contact-info">
                <h6 className="mb-2 links-font">
                  Tarana Media (OPC) Private Limited
                </h6>
                <h6 className="mb-2 links-font">Hyderabad, Telangana</h6>
                <h6 className="links-font" style={{ marginBottom: "24px" }}>
                  CIN: U72900TG2021OPC151126
                </h6>
                <h6 className="mb-2 links-font">Contact:hey@taranamusic.in</h6>
                <h6 className="mb-2 links-font">
                  Career: career@taranamusic.in
                </h6>
                <h6 className="mb-2 links-font">
                  Support: help@taranamusic.in
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center footer-bottom-text pt-3">
        <h6>
          © Copyright 2024 Tarana Media(OPC) Private Limited. All rights are
          reserved.
        </h6>
      </div>
    </footer>
  );
};
export default Footer;
