
import React from "react";
import Footer from "../Layout/footer";
import "./faqsmodule.css";
import logo from "../../assets/images/tarana-logo.png";

// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

<link rel="stylesheet" href="	https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"></link>
 



export const Faqs = () =>{
    return(
    <div>
        <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Tarana logo"
              width="30"
              height="30"
              className="d-inline-block align-text-top logoImage me-2"
            />
            <span className="navbar-brand-text">tarana</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
         <div
            className="collapse navbar-collapse nav-text"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active nav-link-home"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Listeners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  FAQs
                </a>
              </li>
            </ul>
            </div>
        </div>
      </nav>

    
        <div className="container-fluid">
            <h1 className="text-center mt-3 mb-3" style={{fontFamily: "'Cuprum', sanserif"}}>
                FAQ'S
            </h1>
            <h3 className="text-center mt-3 mb-3"  style={{fontFamily: "'Cuprum', sanserif"}}>
                Need help ?
            </h3>
        
        <div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

<div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="true"
        aria-controls="collapseTwo"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseTwo"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

<div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="true"
        aria-controls="collapseThree"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseThree"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

<div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

<div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="true"
        aria-controls="collapseTwo"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseTwo"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

<div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseThree"
        aria-expanded="true"
        aria-controls="collapseThree"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseThree"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

<div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

<div className="accordion" id="accordionExample">
  <div className="accordion-item mb-3" style={{backgroundColor:"#FFEEE6"}}>
    <h2 className="accordion-header" style={{backgroundColor:"#FFEEE6"}}>
      <button
        className="accordion-button custom-accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseTwo"
        aria-expanded="true"
        aria-controls="collapseTwo"
      >
        How does Tarana ensure seamless collaboration among artists?
      </button>
    </h2>
    <div
      id="collapseTwo"
      className="accordion-collapse collapse show"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</strong>
        It is shown by Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
      </div>
    </div>
  </div>
</div>

</div>

<Footer />  
</div>




    );
};