import React from "react";
import logo from "../../assets/images/tarana-logo.png"; // Ensure the path is correct
import Footer from "../Layout/footer";

export const Privacypolicies= () => {
    return (
        <div>
        <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Tarana logo"
              width="30"
              height="30"
              className="d-inline-block align-text-top logoImage me-2"
            />
            <span className="navbar-brand-text">tarana</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
         <div
            className="collapse navbar-collapse nav-text"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link active nav-link-home"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  For Listeners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  Artists
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-link-home" href="#">
                  FAQs
                </a>
              </li>
            </ul>
            </div>
        </div>
      </nav>
          <div className="container-fluid w-100">
        <h1 className="text-center mb-3 mt-3" style={{fontFamily: "'Cuprum',sanserif"}}>
            privacy <span style={{color : "#ff5906"}}>policies</span>
        </h1>
        <div style={{fontFamily: "'Cuprum',sanserif"}}>
        <p className="w-100 p-3" style={{fontSize: "23px",fontWeight: "400"}}>
        Tarana is a groundbreaking platform designed to revolutionize the music industry. It offers a diverse array of services, including social networking, streaming, freelancing, and collaboration opportunities for artists and enthusiasts alike. By integrating these features into a single platform, Tarana aims to create a holistic music ecosystem, providing users with unparalleled access to music, connections, and opportunities. With its innovative approach and commitment to empowering musicians and music lovers, Tarana is poised to redefine the way we experience and engage with music in the digital age.
Tarana is a groundbreaking platform designed to revolutionize the music industry. It offers a diverse array of services, including social networking, streaming, freelancing, and collaboration opportunities for artists and enthusiasts alike. By integrating these features into a single platform, Tarana aims to create a holistic music ecosystem, providing users with unparalleled access to music, connections, and opportunities. With its innovative approach and commitment to empowering musicians and music lovers, Tarana is poised to redefine the way we experience and engage with music in the digital age.
Tarana is a groundbreaking platform designed to revolutionize the music industry. It offers a diverse array of services, including social networking, streaming, freelancing, and collaboration opportunities for artists and enthusiasts alike. By integrating these features into a single platform, Tarana aims to create a holistic music ecosystem, providing users with unparalleled access to music, connections, and opportunities. With its innovative approach and commitment to empowering musicians and music lovers, Tarana is poised to redefine the way we experience and engage with music in the digital age.
Tarana is a groundbreaking platform designed to revolutionize the music industry. It offers a diverse array of services, including social networking, streaming, freelancing, and collaboration opportunities for artists and enthusiasts alike. By integrating these features into a single platform, Tarana aims to create a holistic music ecosystem, providing users with unparalleled access to music, connections, and opportunities. With its innovative approach and commitment to empowering musicians and music lovers, Tarana is poised to redefine the way we experience and engage with music in the digital age.
Tarana is a groundbreaking platform designed to revolutionize the music industry. It offers a diverse array of services, including social networking, streaming, freelancing, and collaboration opportunities for artists and enthusiasts alike. By integrating these features into a single platform, Tarana aims to create a holistic music ecosystem, providing users with unparalleled access to music, connections, and opportunities. With its innovative approach and commitment to empowering musicians and music lovers, Tarana is poised to redefine the way we experience and engage with music in the digital age.
        </p>
        </div>
      </div>

      <Footer/>
    </div>

    );
};